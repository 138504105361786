
*, *::before, *::after {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: #16161a;
    color: #fff;
    font-family: "Poppins", sans-serif;
}

/* Nav Bar */

header nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    animation: fadeInDown .8s;
}

header nav .header_links ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

header nav .header_links ul li, section.main ul li {
    list-style-type: none;
}

header nav .header_links ul li a {
    outline: none;
    text-decoration: none;
    color: #a6a8b8;
    padding: 5px 12px;
    transition: 0.3s all;
    font-size: 15px;
}

header nav .header_links ul li a:hover {
    color: white;
}

header nav .header_logo img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
    border: 5px solid #3b3d4e93;
    cursor: pointer;
}

/* Responsive nav bar */

@media only screen and (max-width: 630px) {
    header nav {
        display: flex;
        flex-direction: column;
    }
    header nav .header_logo {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 320px) {
    header nav .header_links ul {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/* Main */

section.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 70vh;
    animation: fadeInUp .8s;
}

section.main img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
    border: 10px solid #21222b93;
    cursor: pointer;
    margin-bottom: 10px;
}

section.main span {
    color: #fffffe;
    font-size: 30px;
    font-weight: bold;
}

section.main p {
    position: relative;
    top: 50%;
    border-right: 2px solid rgba(255, 255, 255, .75);
    white-space: nowrap;
    overflow: hidden;
    color: #94a1b2;
    font-size: 13px;
    animation: typewriter 4s steps(44) 1s 1 normal both, blinkTextCursor 530ms steps(44) infinite normal;
}

section.main ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

section.main ul li a {
    color: #a6a8b8;
    display: flex;
    text-decoration: none;
    margin: 20px 5px;
    font-size: 25px;
    padding: 5px;
    transition: 0.2s all;
}

section.main ul li a:hover {
    transition: 0.2s all;
    color: #fff;
}

@keyframes typewriter {
    from {
        width: 0;
    }
    to {
        width: 20.5em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, .75);
    }
    to {
        border-right-color: transparent;
    }
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 150px, 0)
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes fadeInDown {
    from {
        transform: translate3d(0, -150px, 0)
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}


