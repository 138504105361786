.navbar {
  background-color:  #16161a;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between; /* Changed from center to space-between */
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
}

.nav-logo {
  color:  #40ff66;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #3b3d4e93;
}

.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #40ff66;
}

.nav-item .active {
  color:  #40ff66;
  border: 1px solid #40ff66;
}

.nav-icon {
  display: none;
  color: #40ff66;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #16161a;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color:  #40ff66;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #40ff66;
  }
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 20px; /* 20px margin on the left */
  text-decoration: none; /* Removes underline from links */
}

.logo-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;

  cursor: pointer;
}

.logo-img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}
