/* Background */
.b_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .b_container::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../../../public/images/ezgif-7ec4b8a6b3b115.gif") center/cover no-repeat;
    filter: blur(5px); 
    z-index: -1;
  }
  
  
  
  /* Profile Card */
  .b_card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    text-align: center;
    width: 350px;
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.2);
  }
  
  /* Profile Picture */
  .profile-pic {
    width: 90px;
    padding: 1px;
    height: 80px;
    margin-left: 120px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.2);
  }
  
  /* Name & Title */
  .b_name {
    color: white;
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .b_title {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
  
  /* Info Buttons */
  .b_info-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .b_info-button {
    background: rgba(255, 255, 255, 0.2);
    padding: 10px 15px;
    border-radius: 20px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .b_info-button:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  /* Social Links */
  .b_social-links {
    margin-top: 20px;
  }
  
  .b_social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    padding: 12px;
    border-radius: 12px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 10px;
    gap: 10px;
  }
  
  .b_social-button:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  
  /* Responsive */
  @media (max-width: 400px) {
    .card {
      width: 90%;
    }
  }
  